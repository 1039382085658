import 'react-toastify/dist/ReactToastify.min.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import './layout.css';
import './prototypes.js';
import './overrides.js';
import './src/locales/config';

import { STORAGE } from 'ducks/storage';
import React from 'react';
import { defineRulesFor, updateAbility } from 'utils/can';
import { getLocalStorage } from 'utils/hooks/storage/useLocalStorage';

import ProvidersWrapper from './ProvidersWrapper';

export const wrapRootElement = ({ element }) => (
	<ProvidersWrapper>
		{element}
	</ProvidersWrapper>
);

export const onClientEntry = () => {
	updateAbilityRules();
};

export const onRouteUpdate = () => {
	updateAbilityRules();
	savePreviousPath();
};

const updateAbilityRules = () => {
	const user = getLocalStorage(STORAGE.key.user, STORAGE.initialState.user);

	if(user?.groups?.length) {
		updateAbility(defineRulesFor(user));
	}
};

const savePreviousPath = () => {
	window.locations = window.locations || [document.referrer];
	window.locations.push(window.location.href);
	window.previousPath = window.locations[window.locations.length - 2];
};
