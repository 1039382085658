import { Ability, AbilityBuilder } from '@casl/ability';
import { useLocalStorage } from '@rehooks/local-storage';
import { ENUM_USER_ROLES } from 'constants/enums';
import { STORAGE } from 'ducks/storage';

const ability = new Ability();

const CAN = (operation, subject) => {
	return ability.can(operation, subject);
};

export const updateAbility = ({ rules }) => {
	ability.update(rules);
};

export default CAN;

export const defineRulesFor = user => {
	const { can, rules } = new AbilityBuilder(Ability);
	const permits = user.permissions;
	const groups = user.groups;

	if (permits && groups) {
		// TODO: remove groups.forEach (old permissions) - use PERM const instead
		// be careful in agreement page
		groups.forEach(group => {
			if(group === ENUM_USER_ROLES.OPERATOR_ADMIN) {
				grantPermissionsForAdministrator(can, permits);

				can('view', 'operator-admin');
				can('view', 'operator');

				// Menu
				can('view', 'view-transfers');
				can('view', 'view-sales');
				can('view', 'submenu-shop-orders');

				// all rows
				can('add', 'row');
				can('edit', 'row');
				can('delete', 'row');
				can('action', 'row');

				// Agreement - view:
				can('view', 'agreement-list');
				can('view', 'agreement-details');
				// Agreement - CUD:
				can('cud', 'agreement-list');
				can('cud', 'agreement-details');
				// Agreement - add:
				can('add', 'agreement-list');
				can('add', 'agreement-details');
				// Agreement - edit:
				can('edit', 'agreement-list');
				can('edit', 'agreement-details');
				// Agreement - delete:
				can('delete', 'agreement-list');
				can('delete', 'agreement-details');
				// Agreement - action:
				can('action', 'agreement-list');
				can('action', 'agreement-details');
				can('action', 'agreement-change-status');
				// Agreement - specific action:
				can('action', 'agreement-cashback');
				can('action', 'agreement-activate');
				can('action', 'agreement-paused');
				can('action', 'agreement-anex');
				can('action', 'agreement-settlement');
				//	Details plate - view
				can('view', 'agreement-program');
				can('view', 'agreement-financing');
				can('view', 'agreement-settings-default');
				can('view', 'agreement-settings-supplier');
				can('view', 'agreement-settings-advanced');
				can('view', 'agreement-settings-administration');
				//	Details plate - CUD
				//	Details plate - add
				can('add', 'agreement-program');
				can('add', 'agreement-financing');
				can('add', 'agreement-settings-default');
				can('add', 'agreement-settings-supplier');
				can('add', 'agreement-settings-advanced');
				can('add', 'agreement-settings-administration');
				//	Details plate - edit
				can('edit', 'agreement-program');
				can('edit', 'agreement-financing');
				can('edit', 'agreement-settings-default');
				can('edit', 'agreement-settings-supplier');
				can('edit', 'agreement-settings-advanced');
				can('edit', 'agreement-settings-administration');
				//	Details plate - delete
				can('delete', 'agreement-program');
				can('delete', 'agreement-financing');
				can('delete', 'agreement-settings-default');
				can('delete', 'agreement-settings-supplier');
				can('delete', 'agreement-settings-advanced');
				can('delete', 'agreement-settings-administration');
				//	Details plate - action
				can('action', 'agreement-program');
				can('action', 'agreement-financing');
				can('action', 'agreement-settings-default');
				can('action', 'agreement-settings-supplier');
				can('action', 'agreement-settings-advanced');
				can('action', 'agreement-settings-administration');

				// Supplier list
				can('action', 'supplier-reset-pin');
				// Supplier details
				can('view', 'supplier-bank-accounts');
				can('view', 'supplier-addresses');
				can('edit', 'supplier-basic-info');
				can('edit', 'supplier-basic-info-specific-base');
				can('edit', 'supplier-bank-accounts');
				can('edit', 'supplier-addresses');
				can('action', 'supplier-show-users');
				can('action', 'supplier-force-regon');

				// Users details
				can('view', 'users-list-item');
				can('view', 'user-details');
				can('edit', 'users-list-item');
				can('edit', 'user-data');
				can('action', 'user-reset-pin');
				can('action', 'user-phone-number-re-auth');
				can('action', 'supplier-block-unblock');

				// Orders list
				can('action', 'change-default-bill-for-final-order');
				// Orders details
				can('view', 'order-details');
				can('action', 'order-generate-transfer');
				can('action', 'order-generate-sales-document');
				can('action', 'order-delete');

				// Invoices list
				can('action', 'expire-invoice');
				can('action', 'final-order-invoice');
				can('action', 'archive-invoice');
			}
			if(group === ENUM_USER_ROLES.OPERATOR_MID) {
				grantPermissionsForAdministrator(can, permits);

				can('view', 'operator-mid');
				can('view', 'operator');

				// UOO - Menu
				can('view', 'view-transfers');
				can('view', 'view-sales');

				// UOO - Agreement - view:
				can('view', 'agreement-list');
				can('view', 'agreement-details');
				// UOO - Agreement - CUD:
				can('cud', 'agreement-list');
				can('cud', 'agreement-details');
				// UOO - Agreement - add:
				can('add', 'agreement-list');
				can('add', 'agreement-details');
				// UOO - Agreement - edit:
				can('edit', 'agreement-list');
				can('edit', 'agreement-details');
				// UOO - Agreement - delete:
				can('delete', 'agreement-list');
				can('delete', 'agreement-details');
				// UOO - Agreement - action:
				can('action', 'agreement-list');
				can('action', 'agreement-details');
				can('action', 'agreement-change-status');
				// UOO - Agreement - specific action:
				can('action', 'agreement-cashback');
				can('action', 'agreement-activate');
				can('action', 'agreement-paused');
				can('action', 'agreement-anex');
				can('action', 'agreement-settlement');
				// UOO -	Details plate - view
				can('view', 'agreement-program');
				can('view', 'agreement-financing');
				can('view', 'agreement-settings-default');
				can('view', 'agreement-settings-supplier');
				can('view', 'agreement-settings-advanced');
				can('view', 'agreement-settings-administration');
				// UOO -	Details plate - CUD
				// UOO -	Details plate - add
				can('add', 'agreement-program');
				can('add', 'agreement-financing');
				can('add', 'agreement-settings-default');
				can('add', 'agreement-settings-supplier');
				can('add', 'agreement-settings-advanced');
				can('add', 'agreement-settings-administration');
				// UOO -	Details plate - edit
				can('edit', 'agreement-program');
				can('edit', 'agreement-financing');
				can('edit', 'agreement-settings-default');
				can('edit', 'agreement-settings-supplier');
				can('edit', 'agreement-settings-advanced');
				can('edit', 'agreement-settings-administration');
				// UOO -	Details plate - delete
				can('delete', 'agreement-program');
				can('delete', 'agreement-financing');
				can('delete', 'agreement-settings-default');
				can('delete', 'agreement-settings-supplier');
				can('delete', 'agreement-settings-advanced');
				can('delete', 'agreement-settings-administration');
				// UOO -	Details plate - action
				can('action', 'agreement-program');
				can('action', 'agreement-financing');
				can('action', 'agreement-settings-default');
				can('action', 'agreement-settings-supplier');
				can('action', 'agreement-settings-advanced');
				can('action', 'agreement-settings-administration');

				// UOO - Supplier list
				// UOO - Supplier details
				can('view', 'supplier-bank-accounts');
				can('view', 'supplier-addresses');
				can('edit', 'supplier-basic-info');
				can('edit', 'supplier-basic-info-specific-base');
				can('edit', 'supplier-bank-accounts');
				can('edit', 'supplier-addresses');
				can('action', 'supplier-show-users');

				// UOO - Users details
				can('view', 'users-list-item');
				can('view', 'user-details');
				can('edit', 'users-list-item');
				can('action', 'user-reset-pin');
				can('action', 'user-phone-number-re-auth');
				can('action', 'supplier-force-regon');
				can('action', 'supplier-block-unblock');

				// UOO - Orders list
				can('action', 'change-default-bill-for-final-order');
				// UOO - Orders details
				can('view', 'order-details');
				can('action', 'order-generate-transfer');
				can('action', 'order-generate-sales-document');
				can('action', 'order-delete');

				// UOO - Invoices list
				can('action', 'final-order-invoice');
				can('action', 'archive-invoice');
			}
			if(group === ENUM_USER_ROLES.OPERATOR_BASIC) {
				grantPermissionsForAdministrator(can, permits);

				can('view', 'operator-basic');
				can('view', 'operator');

				// UOS - Menu
				can('view', 'submenu-shop-orders');

				// UOS - Agreement - view:
				can('view', 'agreement-list');
				// UOS - Agreement - CUD:
				// UOS - Agreement - add:
				// UOS - Agreement - edit:
				// UOS - Agreement - delete:
				// UOS - Agreement - action:
				// UOS - Agreement - specific action:
				// UOS - Details plate - view
				// UOS - Details plate - CUD
				// UOS - Details plate - add
				// UOS - Details plate - edit
				// UOS - Details plate - delete
				// UOS - Details plate - action

				// UOS - Supplier list
				can('action', 'supplier-reset-pin');
				// UOS - Supplier details
				can('view', 'supplier-bank-accounts');
				can('view', 'supplier-addresses');
				can('edit', 'supplier-basic-info');
				can('edit', 'supplier-basic-info-specific-base');
				can('action', 'supplier-show-users');
				can('action', 'supplier-force-regon');

				// UOS - Users details
				can('view', 'users-list-item');
				can('view', 'user-details');
				can('edit', 'user-data');

				// UOS - Invoices list
				can('action', 'expire-invoice');
			}
			if(group === ENUM_USER_ROLES.ANIMATOR_ADMIN) {
				grantPermissionsForAnimator(can, permits);

				can('view', 'animator');
				can('view', 'animator-admin');

				// Menu
				can('view', 'submenu-shop-orders');

				// UA - Agreement:
				can('view', 'agreement-list');
				// UA - Details plate - view
				can('view', 'agreement-program');
				can('view', 'agreement-settings-default');
				// UA - Details plate - CUD
				// UA - Details plate - add
				can('add', 'agreement-settings-default');
				// UA - Details plate - edit
				can('edit', 'agreement-settings-default');
				// UA - Details plate - delete
				can('delete', 'agreement-settings-default');
				// UA - Details plate - action
				can('action', 'agreement-settings-default');

				// UA - Supplier list
				// UA - Supplier details
				can('view', 'supplier-bank-accounts');
				can('view', 'supplier-addresses');
				can('edit', 'supplier-bank-accounts');
				can('edit', 'supplier-addresses');
				can('edit', 'supplier-basic-info');
				can('action', 'supplier-show-users');
				can('action', 'supplier-block');
				can('action', 'supplier-unblock');
				can('action', 'supplier-delete-from-agreement');
				can('action', 'supplier-reauthorize');

				// UA - Users details
				// UA - Invoices list
			}
			if(group === ENUM_USER_ROLES.ANIMATOR_MID) {
				grantPermissionsForAnimator(can, permits);
				can('view', 'animator');
				can('view', 'animator-mid');
			}
			if(group === ENUM_USER_ROLES.ANIMATOR_BASIC) {
				grantPermissionsForAnimator(can, permits);
				can('view', 'animator');
				can('view', 'animator-basic');
			}
		});
	}

	return { can, rules };
};

/**
 * Available permissions example:
CAN('read', 'invoice')
CAN('adwRead', 'invoice')
CAN('adwEdit', 'transfers-import')

 * Formula:
operating mode (null | adw | adx | uax | uaxb)
type (R/read | E/edit)
permission (invoice | transfers-import)
 **/
const grantPermission = (can, permit, property) => {
	const hasPermission = permit[property];

	if(hasPermission) {
		can(property, permit.permission);
	}
};

const grantPermissionsForAnimator = (can, permits) => {
	for (const permit of permits) {
		grantPermission(can, permit, 'read');
		grantPermission(can, permit, 'edit');
	}
};

const grantPermissionsForAdministrator = (can, permits) => {
	for (const permit of permits) {
		grantPermission(can, permit, 'cmnRead');
		grantPermission(can, permit, 'cmnEdit');
		grantPermission(can, permit, 'adwRead');
		grantPermission(can, permit, 'adwEdit');
		grantPermission(can, permit, 'adxRead');
		grantPermission(can, permit, 'adxEdit');
		grantPermission(can, permit, 'uaxRead');
		grantPermission(can, permit, 'uaxEdit');
		grantPermission(can, permit, 'uaxbRead');
		grantPermission(can, permit, 'uaxbEdit');
	}
};

export const PERM_OPERATION = {
	READ: 'read',
	EDIT: 'edit'
};

export const PERM_CONTEXT = {
	CMN: 'cmn', // common
	ADW: 'adw',
	ADX: 'adx',
	UAX: 'uax',
	UAXB: 'uaxb',
};

const getPermissions = resource => (operation = PERM_OPERATION.READ, context = PERM_CONTEXT.CMN) => {
	let hasPermission = false;

	// has own context
	if(PERM.IS_ANIMATOR()) {
		hasPermission = CAN(operation, resource);
	} else if(PERM.IS_ADMIN()) {
		const instance = `${context}${operation.toUpperCaseChar()}`;
		hasPermission = CAN(instance, resource);
	}

	return hasPermission;
};

// use Permissions over the current context
export function usePermissions() {
	const [{ permissions }] = useLocalStorage(STORAGE.key.user, STORAGE.initialState.user);

	const permInvoice = permissions?.find(item => item.permission === 'invoice.section');
	const permOrder = permissions?.find(item => item.permission === 'order.section');
	const permTransfer = permissions?.find(item => item.permission === 'transfer.section');
	const permSalesDoc = permissions?.find(item => item.permission === 'sales_document.section');

	const hasInvoiceSection = permInvoice?.read || permInvoice?.adwRead || permInvoice?.adxRead || permInvoice?.uaxRead || permInvoice?.uaxbRead;
	const hasOrderSection = permOrder?.read || permOrder?.adwRead || permOrder?.adxRead || permOrder?.uaxRead || permOrder?.uaxbRead;
	const hasTransferSection = permTransfer?.read || permTransfer?.adwRead || permTransfer?.adxRead || permTransfer?.uaxRead || permTransfer?.uaxbRead;
	const hasSalesSection = permSalesDoc?.read || permSalesDoc?.adwRead || permSalesDoc?.adxRead || permSalesDoc?.uaxRead || permSalesDoc?.uaxbRead;

	return {
		hasInvoiceSection,
		hasOrderSection,
		hasTransferSection,
		hasSalesSection
	};
}

// READ / EDIT
export const PERM = {
	// DEFAULT
	DEFAULT_ANIMATOR: getPermissions('default.animator'), // "Domyślne animatora"  # domyślne uprawnienia. Animator powinien mieć w tym zakresie wszystkie uprawnienia w wszystkich trybach obsługi
	// USERS
	IS_ADMIN: () => CAN('view', 'operator'), // AD | ADO | ADS
	IS_AD: () => CAN('view', 'operator-admin'), // AD
	IS_ADO: () => CAN('view', 'operator-mid'), // ADO
	IS_ADS: () => CAN('view', 'operator-basic'), // ADS
	IS_ANIMATOR: () => CAN('view', 'animator'), // UA | UAP | UAO
	IS_UA: () => CAN('view', 'animator-admin'), // UA
	IS_UAP: () => CAN('view', 'animator-mid'), // UAP
	IS_UAO: () => CAN('view', 'animator-basic'), // UAO
	// DASHBOARD
	DASHBOARD_ANIMATOR: getPermissions('dashboard.animator'),
	DASHBOARD_OPERATOR: getPermissions('dashboard.operator'),
	// INVOICE - OPERATOR context
	INVOICE_SECTION: getPermissions('invoice.section'), // "Sekcja Faktury"
	INVOICE_CREATOR: getPermissions('invoice.creator'), // "Faktury - Kreator Faktur"  # (dodaj faktury, plus na listingu faktur, import z pliku, publikuj, usuń, edycja faktury)
	INVOICE_FAST_ADD: getPermissions('invoice.fast_add'), // "Faktury - Dodaj szybko"
	INVOICE_SCHEDULE: getPermissions('invoice.schedule'), // "Faktury - Harmonogram publikacji"
	INVOICE_PAUSE: getPermissions('invoice.pause'), // "Faktury - Wstrzymaj"
	INVOICE_RESTORE_TO_EDIT: getPermissions('invoice.restore_to_edit'), // "Faktury - Przywróć do edycji"
	INVOICE_EXPIRY: getPermissions('invoice.expiry'), // "Faktury - Wygaś"
	INVOICE_ARCHIVE: getPermissions('invoice.archive'), // "Faktury - Archiwum"  # (przycisk, zakładka)
	INVOICE_FINAL_ORDER: getPermissions('invoice.final_order'), // "Faktury - Zlec. Końcowe"
	INVOICE_CLEARED: getPermissions('invoice.invoice_cleared'), // "Faktury - Faktury rozliczone"
	INVOICE_PROLONGATE: getPermissions('invoice.prolongate'), // "Faktury - Prolongata"
	INVOICE_DETAILS: getPermissions('invoice.details'), // "Faktury - Faktura szczegóły"
	INVOICE_ADVANCED_REGISTER: getPermissions('invoice.advanced_register'), // "Faktury - Rejestr Zaawansowany"
	// SUPPLIER
	SUPPLIER_SECTION: getPermissions('supplier.section'), // "- Sekcja Dostawcy -"
	SUPPLIER_SEND_INVITATION: getPermissions('supplier.send_invitation'), // "Dostawcy - Wyślij zaproszenie"
	SUPPLIER_RESET_PIN: getPermissions('supplier.reset_pin'), // "Dostawcy - Resetuj PIN użytkownika"
	SUPPLIER_BLOCK: getPermissions('supplier.block'), // "Dostawcy - Blokowanie"  # przycisk na listingu i w szczegółach dostacy
	SUPPLIER_DETAILS: getPermissions('supplier.details'), // "Dostawcy - Dostawcy szczegóły/podstawowe"
	SUPPLIER_DETAILS_STATUS_AND_COMMITMENT: getPermissions('supplier.details_status_and_commitment'), // "Dostawcy - Dostawcy szczegóły/status i zaangażowanie"
	SUPPLIER_DETAILS_ADDRESSES: getPermissions('supplier.details_addresses'), // "Dostawcy - Dostawcy szczegóły/adresy"
	SUPPLIER_DETAILS_BANK_ACCOUNTS: getPermissions('supplier.details_bank_accounts'), // "Dostawcy - Dostawcy szczegóły/rachunki bankowe"
	SUPPLIER_REAUTORIZE: getPermissions('supplier.reautorize'), // "Dostawcy - Reautoryzuj"
	SUPPLIER_DOWNLOAD_REGON_DATA: getPermissions('supplier.download_regon_data'), // "Dostawcy - Wymuś pobranie danych z regonu"
	SUPPLIER_SHOW_USERS: getPermissions('supplier.show_users'), // "Dostawcy - Zobacz użytkowników"
	SUPPLIER_FAST_EDIT: getPermissions('supplier.fast_edit'), // "Dostawcy - Szybka edycja"
	// ORDER - OPERATOR context
	ORDER_SECTION: getPermissions('order.section'), // "- Sekcja Zlecenia -"  # zakładka w fakturach wypłaty
	ORDER_CANCELLATION: getPermissions('order.cancellation'), // "Zlecenia -  Anulowanie zlecenia"
	ORDER_DETAILS: getPermissions('order.details'), // "Zlecenia -  Zlecenie szczegóły/podstawowe"
	ORDER_DETAILS_MOBILE: getPermissions('order.details_mobile'), // "Zlecenia -  Zlecenie szczegóły/Zl. mobilne"  # Dokumenty sprzedaży, szczegóły, zobacz opłaty
	// TRANSFER - OPERATOR context
	TRANSFER_SECTION: getPermissions('transfer.section'), // "- Sekcja Przelewy -"  # przelewy wychodzące + "Rozliczenie dyskonta"
	TRANSFER_GENERATE: getPermissions('transfer.generate'), // "Przelewy - Generuj przelew"  # generuj przelew na listingu (pliki eksportu i importu na potrzeby potwierdzania przelewów wychodzących, Zlecenia/szczegóły/Rozliczanie Dyskonta)
	TRANSFER_FILES: getPermissions('transfer.files'), // "Przelewy - Pliki przelewów"
	TRANSFER_EXPORT: getPermissions('transfer.export'), // "Przelewy - Eksport"
	TRANSFER_IMPORT: getPermissions('transfer.import'), // "Przelewy - Import"
	TRANSFER_OUTSIDE_SYSTEM: getPermissions('transfer.outside_system'), // "Przelewy - Zrealizowano poza systemem"  # [opcja wyłącznie możliwa przy kapitale obcym, gdy brak ręcznego rozliczanie przelewów wychodzących]
	TRANSFER_OUTGOING_UNRELATED: getPermissions('transfer.outgoing_unrelated'), // "Przelewy - Przelewy wychodzące niepowiązane"  # Zakładka i rozlicz ręcznie ze zleceniem
	TRANSFER_OUTGOING_DETAILS: getPermissions('transfer.outgoing_details'), // "Przelewy - Przelewy wychodzące szczegóły/podstawowe"  # i zakładka w fakturach - rozrachunki/rozliczenia
	TRANSFER_OUTGOING_DETAILS_CLEARED_ORDERS: getPermissions('transfer.outgoing_details_cleared_orders'), // "Przelewy - Przelewy wychodzące szczegóły/Zlecenia rozliczone"  # Rozlicz i Zlecenie/szczegóły/rozliczenie dyskonta
	TRANSFER_ADVANCED_IMPORT: getPermissions('transfer.advanced_import'), // "Przelewy - Import zaawansowany"
	TRANSFER_INCOMING: getPermissions('transfer.incoming'), // "Przelewy - Przelewy przychodzące"  # Import przelewów przychodzących ( rozliczanie, do poczekalni, poza faktoringiem, usuwanie, zwroty, przekazy, przelewy przychodzące/faktury rozliczone)
	TRANSFER_INCOMING_DETAILS: getPermissions('transfer.incoming_details'), // "Przelewy - Przelewy przychodzące szczegóły"  # Przelewy przychodzące/szczegóły i Import przelewów przychodzących (rozliczanie, do poczekalni, poza faktoringiem, usuwanie, zwroty, przekazy, przelewy przychodzące/faktury rozliczone)
	// SALES
	SALES_SECTION: getPermissions('sales_document.section'), // "- Sekcja Sprzedaż -"
	SALES_DOCUMENT_DETAILS: getPermissions('sales_document.details'), // "Sprzedaż - Sprzedaż/podstawowe"  # podgląd (akcja pobierz dokument) + szczegóły zlecenia/sprzedaż
	SALES_DOCUMENT_GENERATE: getPermissions('sales_document.generate'), // "Sprzedaż - Generuj dok. sprzedaży"  # Na liście zleceń // "Sprzedaż - Procesowanie dok. sprzedaży"  # Rozlicz, księguj // "Sprzedaż - Sprzedaż/edycja"  # Akcja przegeneruj
	// USER
	USER_SECTION: getPermissions('user.section'), // "- Sekcja Użytkownicy -"
	USER_DETAILS: getPermissions('user.details'), // "Użytkownicy - Użytkownicy szczegóły"  # blokowanie
	// HELP
	HELP_SECTION: getPermissions('help.section'), // "- Sekcja Pomoc -"
	// we don't need this permission for animator
	ANIMATOR_AGREEMENT_SECTION: getPermissions('animator_agreement.section'), // "- Sekcja Umowa animatora -"  # pojedyncza umowa z punktu widzenia animatora
	// AGREEMENT
	AGREEMENT_SECTION: getPermissions('agreement.section'), // "- Sekcja Umowy animacji -"  # raporty
	AGREEMENT_DETAILS: getPermissions('agreement.details'), // "Umowy animacji - Umowa szczegóły"  # tworzenie umowy, statusowanie
	// PROFILE
	PROFILE_SECTION: getPermissions('profile.section'), // "- Sekcja Profil użytkownika -"  # umowa, zmień hasło, ustawienia
	PROFILE_SHOP: getPermissions('profile.shop'), // "Profil użytkownika - Sklep z nagrodami"  # dostępne nagrody, zamówienia, adres wysyłki
	// STORE_ORDERS
	STORE_ORDERS_SECTION: getPermissions('store_orders.section'), // "- Sekcja Zamówienia w sklepie"
	STORE_ORDERS_STATUS: getPermissions('store_orders.status'), // "- Zamówienia w sklepie - Statusowanie"
	// REPORT
	REPORT_SECTION: getPermissions('report.section'), // "- Sekcja Raporty -"
	REPORT_DISCOUNTED_INVOICES: getPermissions('report.discounted_invoices'), // "Raporty animatora - Faktury zdyskontowane"
	REPORT_ANIMATOR_PAYMENTS: getPermissions('report.animator_payments'), // "Raporty animatora - Wpłaty animatora"
	REPORT_CASHBACK: getPermissions('report.cashback'), // "Raporty animatora - Cashback"
	// reports that are not yet available
	REPORT_SETTLE_INVOICE_WITH_SUPPLIER: getPermissions('report.settle_invoice_with_supplier'), // "Raporty animatora - Rozliczenia z dostawcami"
	REPORT_GENERATE: getPermissions('report.generate')
};
