const translatePaths = {
	pl: {
		'sales.status.generated': 'Wygenerowany',
		'sales.status.settle': 'W rozliczeniu',
		'sales.status.realized': 'Rozliczony',
		'sales.status.booked': 'Zaksięgowany',
		'sales.type.domestic': 'Krajowa',
		'sales.type.union': 'Unijna',
		'sales.type.export': 'Exportowa',
		'text.companyType': 'Typ firmy',
		'text.animators': 'Animatorzy',
		'text.suppliers': 'Dostawcy',
		'placeholder.choose': 'Wybierz',
		'yup.required': 'Pole wymagane.',
		'yup.number.required': 'Wymagana poprawna liczba.',
		'yup.string.required': 'Wymagana poprawna wartość.',
		'yup.string.email': 'Email jest niepoprawny.',
		'yup.string.min': 'Min ilość znaków: ${min}',
		'yup.string.max': 'Max ilość znaków: ${max}',
		'yup.number.positive': 'Wymagana liczba dodatnia.',
		'yup.number.min': 'Min wartość: ${min}',
		'yup.number.max': 'Max wartość: ${max}',
		'yup.date.min': 'Data nie może być przeszła.',
		'yup.date.max': 'Data nie może być przyszła.',
		'yup.polandNip': 'Nip jest niepoprawny.',
		'yup.polandPhoneNumber': 'Niepoprawny numer telefonu dla tego kraju.',
		'yup.polandNrb': 'Numer konta bankowego nie spełnia wymagań NRB.',
		'yup.polandPostalCode': 'Niepoprawny format.',
		'empty.formatBankNumber': 'Brak',
		'empty.formatAddress': 'Brak',
		'empty.formatAddressCommune': 'Brak',
		'empty.street': 'ul. ',
		'empty.commune': 'Gmina:',
		'empty.district.part.1a': ', p',
		'empty.district.part.1b': 'P',
		'empty.district.part.2': 'owiat:',
		'empty.province': 'Województwo:',
		'empty.country': 'Kraj:',
		'empty.formatNip': 'Brak',
		'empty.readonly': 'Brak',
		'boolean.yes': 'Tak',
		'boolean.no': 'Nie',
		'options.notificationEmailGroups.name.1': 'Profilowe i systemowe',
		'options.notificationEmailGroups.name.2': 'Umowa animacji',
		'options.notificationEmailGroups.name.3': 'Faktury i zlec. dostawcy',
		'options.notificationEmailGroups.name.4': 'Marketing',
		'options.notificationEmailGroups.name.5': 'Pomoc',
		'options.notificationPersonGroups.name.1': 'Profilowe i systemowe',
		'options.notificationPersonGroups.name.2': 'Umowa animacji',
		'options.notificationPersonGroups.name.3': 'Faktury i zlec. dostawcy',
		'options.notificationPersonGroups.name.4': 'Marketing',
		'options.notificationPersonGroups.name.5': 'Pomoc'
	},
	en: {
		'sales.status.generated': 'Generated',
		'sales.status.settle': 'In settlement',
		'sales.status.realized': 'Settled',
		'sales.status.booked': 'Booked',
		'sales.type.domestic': 'Domestic',
		'sales.type.union': 'Union',
		'sales.type.export': 'Export',
		'text.companyType': 'Company Type',
		'text.animators': 'Animators',
		'text.suppliers': 'Suppliers',
		'placeholder.choose': 'Choose',
		'yup.required': 'Required field.',
		'yup.number.required': 'Valid number required.',
		'yup.string.required': 'Valid value required.',
		'yup.string.email': 'Email is invalid.',
		'yup.string.min': 'Min number of characters: ${min}',
		'yup.string.max': 'Max characters: ${max}',
		'yup.number.positive': 'Positive number required.',
		'yup.number.min': 'Min value: ${min}',
		'yup.number.max': 'Max value: ${max}',
		'yup.date.min': 'Date cannot be past.',
		'yup.date.max': 'The date cannot be future.',
		'yup.polandNip': 'Nip is invalid.',
		'yup.polandPhoneNumber': 'Invalid phone number for this country.',
		'yup.polandNrb': 'Bank account number does not meet NRB requirements.',
		'yup.polandPostalCode': 'Invalid format.',
		'empty.formatBankNumber': 'None',
		'empty.formatAddress': 'None',
		'empty.formatAddressCommune': 'None',
		'empty.street': 'st. ',
		'empty.commune': 'Commune:',
		'empty.district.part.1a': ', d',
		'empty.district.part.1b': 'D',
		'empty.district.part.2': 'istrict:',
		'empty.province': 'Province:',
		'empty.country': 'Country:',
		'empty.formatNip': 'None',
		'empty.readonly': 'None',
		'boolean.yes': 'Yes',
		'boolean.no': 'No',
		'options.notificationEmailGroups.name.1': 'User profile',
		'options.notificationEmailGroups.name.2': 'Agreement',
		'options.notificationEmailGroups.name.3': 'Invoices and orders',
		'options.notificationEmailGroups.name.4': 'Marketing',
		'options.notificationEmailGroups.name.5': 'Help',
		'options.notificationPersonGroups.name.1': 'User profile',
		'options.notificationPersonGroups.name.2': 'Agreement',
		'options.notificationPersonGroups.name.3': 'Invoices and orders',
		'options.notificationPersonGroups.name.4': 'Marketing',
		'options.notificationPersonGroups.name.5': 'Help'
	}
};

export default translatePaths;