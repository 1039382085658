const suppressedWarnings = [
	'Warning: React does not recognize the `%s` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `%s` instead. If you accidentally passed it from a parent component, remove it from the DOM element.%s',
	'Warning: Each child in a list should have a unique "key" prop.%s%s See https://reactjs.org/link/warning-keys for more information.%s',
	'Warning: validateDOMNesting(...): %s cannot appear as a child of <%s>.%s%s%s',
	'Warning: validateDOMNesting(...): %s cannot appear as a descendant of <%s>.%s',
	'Warning: Invalid value for prop %s on <%s> tag. Either remove it from the element, or pass a string or number value to keep it in the DOM. For details, see https://reactjs.org/link/attribute-behavior %s'
];

const backupWarn = console.warn;
const backupError = console.error;

console.warn = function filterWarnings(msg) {
	if (typeof msg === 'string' && !suppressedWarnings.some(entry => msg.includes(entry))) {
		backupWarn.apply(console, arguments);
	}
};

// In React: Warnings use error console function
console.error = function filterWarnings(msg) {
	if (typeof msg === 'string' && !suppressedWarnings.some(entry => msg.includes(entry))) {
		backupError.apply(console, arguments);
	}
};
