import { isBrowser } from 'utils/helper';
import { ENUM_STORAGE_ACCESS_TOKEN, ENUM_STORAGE_LANGUAGE, ENUM_STORAGE_REFRESH_TOKEN } from 'utils/hooks/storage/storageActions';

export const getStorageAccessToken = () => {
	let token = null;
	if(isBrowser) {
		token = sessionStorage.getItem(ENUM_STORAGE_ACCESS_TOKEN);
	}
	return token;
};

export const getStorageRefreshToken = () => {
	let token = null;
	if(isBrowser) {
		token = sessionStorage.getItem(ENUM_STORAGE_REFRESH_TOKEN);
	}
	return token;
};

export const getStorageLanguage = () => {
	let language = null;

	if(isBrowser) {
		language = localStorage.getItem(ENUM_STORAGE_LANGUAGE);
		if(language?.length > 2) {
			language = `${language[0]}${language[1]}`;
		}
	}
	return language ?? 'en';
};
