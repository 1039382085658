import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import en from './en/translation.json';
import pl from './pl/translation.json';

const resources = {
	en: { translation: en },
	pl: { translation: pl }
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next).init({
		resources,
		fallbackLng: 'en',
		keySeparator: '.',
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
