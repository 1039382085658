
export const ENUM_USER_ROLES = {
	SUPPLIER: 'sup', // Supplier
	ANIMATOR_ADMIN: 'ua', // Animator admin
	ANIMATOR_MID: 'uap', // Animator Pełny
	ANIMATOR_BASIC: 'uao', // Animator Odczyt
	OPERATOR_ADMIN: 'ad', // Administrator admin
	OPERATOR_MID: 'ado', // Administrator Operacyjny
	OPERATOR_BASIC: 'ads', // Administrator Standard
};

export const ENUM_COMPANY_TYPE = {
	AGREEMENT_OPERATOR: 'agreement-operator',
	ANIMATOR: 'animator',
	SUPPLIER: 'supplier',
	SALES_DOCUMENT_ISSUER: 'sales-document-issuer',
	ADMIN: 'administrator'
};

export const ENUM_BANK_ACCOUNT_TYPE = {
	NRB: 1,
	IBAN: 2,
	FOREIGN: 3,
};

export const ENUM_INVOICE_REGISTER_PURCHASES = {
	SIMPLE: 1,
	ADVANCED: 2
};

export const TAB_INVOICES_N_SUPPLIERS = {
	NONE: 'none',
	ALL: 'all',
	PUBLISHED: 'published',
	WAITING_ROOM: 'waiting-room',
	PAUSED: 'paused',
	EXPIRED: 'expired',
	NO_ACCOUNT: 'no-account',
	NO_PHONE: 'no-phone',
	NO_NAME: 'no-name',
	BLOCKED: 'blocked',
	CLEARED: 'cleared',
	CLEARED_FINAL_ORDER: 'cleared-final-order',
	REGISTRY: 'registry',
	ARCHIVE: 'archive',
};

export const STATUS_INVOICES = {
	BUFFER: 0,
	WAITING_ROOM: 1,
	PUBLISHED: 2,
	PAUSED: 3,
	EXPIRED: 4,
	CLEARED_FINAL_ORDER: 5,
	CLEARED: 6,
	ARCHIVE: 7
};

export const INPUT = {
	KEY: {
		SUBMIT: 13,
		ESCAPE: 27,
		LEFT_ARROW: 37,
		RIGHT_ARROW: 39,
	},
};

export const CONST = {
	ALL_SLUG_ROUTE: 'common',
	RESPONSE: {
		STATUS_200: 200,
		STATUS_202: 202,
		STATUS_204: 204,
		STATUS_400: 400,
		STATUS_401: 401,
		STATUS_403: 403,
		STATUS_404: 404,
		STATUS_409: 409,
		STATUS_413: 413,
		STATUS_429: 429,
		STATUS_500: 500,
	}
};
