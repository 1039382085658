import { getParamNames, isBrowser } from 'utils/helper';

const getStoredValue = (key, keyName, initialValue) => {
	if(isBrowser) {
		if (key instanceof Function) {
			const mainState = window.localStorage.getItem(keyName);
			const nestedState = key(JSON.parse(mainState));
			return nestedState ? nestedState : initialValue;
		} else {
			const item = window.localStorage.getItem(keyName);
			return item?.includes('{') ? JSON.parse(item) : item ? item : initialValue;
		}
	}
	return initialValue;
};

export const getLocalStorage = (key, initialValue = {}) => {
	const keyName = key instanceof Function ? getParamNames(key)[0] : key;
	return getStoredValue(key, keyName, initialValue);
};