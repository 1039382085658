// TODO: subject -> adw|adx|uax|uaxb|animatorSlug
// TODO: :subject/slug -> slug|common
export const routes = {
	PAGE_NOT_FOUND: '/404',
	DASHBOARD: '/dashboard',
	SIGN_IN: '/login',
	INVOICES: '/invoices',
	INVOICES_CONTEXT: '/invoices/:subject',
	INVOICES_CONTEXT_BY_AGREEMENT: '/invoices/:subject/:agreementSlug',
	INVOICES_ALL_ADW: '/invoices/adw/common',
	SALES: '/sales',
	SALES_CONTEXT: '/sales/:subject',
	SALES_CONTEXT_BY_AGREEMENT: '/sales/:subject/:agreementSlug',
	SALES_ALL_ADW: '/sales/adw/common',
	SALES_ALL_ADX: '/sales/adx',
	SALES_ALL_UAX: '/sales/uax/common',
	SALES_ALL_UAXB: '/sales/uaxb/common',
	SUPPLIERS: '/suppliers',
	SUPPLIERS_OBJECT: '/suppliers/:slug',
	REPORTS: '/reports',
	ORDERS: '/orders',
	ORDERS_CONTEXT: '/orders/:subject',
	ORDERS_ALL_ADW: '/orders/adw/common',
	ORDERS_CONTEXT_BY_AGREEMENT: '/orders/:subject/:agreementSlug',
	TRANSFERS: '/transfers',
	TRANSFERS_CONTEXT: '/transfers/:subject',
	TRANSFERS_VIEW: '/transfers/:view',
	TRANSFERS_ALL_ADW: '/transfers/adw/common/outgoing',
	TRANSFERS_CONTEXT_BY_AGREEMENT: '/transfers/:subject/:agreementSlug/:view',
	TRANSFERS_OUTGOING_PATH: '/transfers/:subject/:agreementSlug/outgoing',
	TRANSFERS_INCOMING_PATH: '/transfers/:subject/:agreementSlug/incoming',
	TRANSFERS_IMPORT_FILES_PATH: '/transfers/:subject/:agreementSlug/import-files',
	TRANSFERS_EXPORT_FILES_PATH: '/transfers/:subject/:agreementSlug/export-files',
	TRANSFERS_OUTGOING: '/outgoing',
	TRANSFERS_INCOMING: '/incoming',
	TRANSFERS_IMPORT_FILES: '/import-files',
	TRANSFERS_EXPORT_FILES: '/export-files',
	USERS: '/users',
	USERS_OBJECT: '/users/:slug',
	HELP: '/help',
	PROFILE: '/profile',
	SHOP_ORDERS: '/shop-orders',
	PROMOTIONS: '/promotions',
	AGREEMENTS: '/agreements',
	AGREEMENTS_LIST: '/agreements?tab=active',
	AGREEMENTS_OBJECT: '/agreements/:slug',
	AGREEMENTS_OBJECT_REPORTS_BY_CONTEXT: '/agreements/:context/:agreementSlug/reports',
	AGREEMENTS_OBJECT_MODALS_REPORTS_ITEM: '/agreements/:context/:agreementSlug/reports/:slug',
	DASHBOARD_ITEM: '/dashboard/:slug',
	TRANSFER_ITEM_OUTGOING_BY_CONTEXT: '/transfers/:subject/:agreementSlug/outgoing/:slug',
	TRANSFER_ITEM_OUTGOING: '/transfers/outgoing/:slug',
	TRANSFER_ITEM_INCOMING_BY_CONTEXT: '/transfers/:subject/:agreementSlug/incoming/:slug',
	TRANSFER_ITEM_INCOMING: '/transfers/incoming/:slug',
	REPORT_ITEM: '/reports/:slug',
	INVOICE_ITEM: '/invoices/:slug',
	INVOICE_ITEM_BY_CONTEXT: '/invoices/:subject/:agreementSlug/:slug',
	SALE_ITEM: '/sales/:subject/:agreementSlug/:slug',
	ORDER_ITEM_BY_CONTEXT: '/orders/:subject/:agreementSlug/:slug',
	ORDER_ITEM: '/orders/:slug',
	SHOP_ORDERS_ITEM: '/shop-orders/:slug',
	ADD_INVOICES: '/invoices/creator',
	PL: {
		COMPLIANCE: '/pl/regulamin/finteq-polityka-prywatnosci'
	}
};
