import * as Sentry from '@sentry/gatsby';
import { routes } from 'constants/routes';
import { navigate } from 'gatsby';
import { ThemeProvider } from 'providers/ThemeProvider';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { GlobalStyles } from 'twin.macro';
import useCypressDefineRules from 'utils/hooks/useCypressDefineRules';

import json from './package.json';

function ErrorFallback({ error, resetErrorBoundary }) {
	useEffect(() => {
		if(error) {
			Sentry.captureException(error);
		}
	}, [error]);

	const navigateToApplication = () => {
		navigate(routes.DASHBOARD);
		resetErrorBoundary();
	};

	return (
		<div className='flex flex-col items-center justify-center m-20'>
			<p>Coś poszło nie tak.</p>
			<button tw='bg-blue-1 text-09 text-light rounded-10 px-05 my-05' onClick={resetErrorBoundary}>Spróbuj ponownie</button>
			<button tw='bg-green-1 text-09 text-light rounded-10 px-05' onClick={navigateToApplication}>Wróć do aplikacji</button>
		</div>
	);
}

export default function ProvidersWrapper({ children }) {
	useCypressDefineRules();

	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<CookiesProvider>
				<ThemeProvider>
					<Helmet>
						<html lang="pl-PL" />
						<meta charSet="utf-8" />
						<meta name="version" content={json.version} />
						<title>Finteq</title>
					</Helmet>

					<GlobalStyles />

					{children}
				</ThemeProvider>
			</CookiesProvider>
		</ErrorBoundary>
	);
}
