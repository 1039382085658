export const STORAGE = {
	key: {
		context: 'context',
		commons: 'commons',
		options: 'options',
		user: 'user'
	},
	initialState: {
		context: {
		},
		commons: {
		},
		options: {
			// Be carefull to change key name
			paginationLimitTable: 15,
			paginationLimitList: 10, // paginationLimitModalTable
			paginationLimitNotes: 5,
			defaultCurrency: null
		},
		user: {
			firstName: '',
			lastName: '',
			email: '',
			groups: [],
			agreements: [],
			agreement: {},
			invoicesNumberInBuffer: null
		}
	}
};
