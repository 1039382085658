import { useLocalStorage } from '@rehooks/local-storage';
import { ENUM_USER_ROLES } from 'constants/enums';
import { useEffect } from 'react';
import { defineRulesFor, updateAbility } from 'utils/can';
import { ENUM_STORAGE_CYPRESS_ROLE } from 'utils/hooks/storage/storageActions';

export default function useCypressDefineRules() {
	const [ role ] = useLocalStorage(ENUM_STORAGE_CYPRESS_ROLE, ENUM_USER_ROLES.ANIMATOR_ADMIN);

	useEffect(() => {
		if(window.Cypress) {
			updateAbility(defineRulesFor({ groups: [role] }));
		}
	}, []);
}
