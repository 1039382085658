exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agreements-jsx": () => import("./../../../src/pages/agreements.jsx" /* webpackChunkName: "component---src-pages-agreements-jsx" */),
  "component---src-pages-compliance-finteq-privacy-policy-jsx": () => import("./../../../src/pages/compliance/finteq-privacy-policy.jsx" /* webpackChunkName: "component---src-pages-compliance-finteq-privacy-policy-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-help-jsx": () => import("./../../../src/pages/help.jsx" /* webpackChunkName: "component---src-pages-help-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-invoices-creator-jsx": () => import("./../../../src/pages/invoices/creator.jsx" /* webpackChunkName: "component---src-pages-invoices-creator-jsx" */),
  "component---src-pages-invoices-index-jsx": () => import("./../../../src/pages/invoices/index.jsx" /* webpackChunkName: "component---src-pages-invoices-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-orders-jsx": () => import("./../../../src/pages/orders.jsx" /* webpackChunkName: "component---src-pages-orders-jsx" */),
  "component---src-pages-pl-regulamin-finteq-polityka-prywatnosci-jsx": () => import("./../../../src/pages/pl/regulamin/finteq-polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-pl-regulamin-finteq-polityka-prywatnosci-jsx" */),
  "component---src-pages-profile-jsx": () => import("./../../../src/pages/profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */),
  "component---src-pages-promotions-jsx": () => import("./../../../src/pages/promotions.jsx" /* webpackChunkName: "component---src-pages-promotions-jsx" */),
  "component---src-pages-reports-jsx": () => import("./../../../src/pages/reports.jsx" /* webpackChunkName: "component---src-pages-reports-jsx" */),
  "component---src-pages-sales-jsx": () => import("./../../../src/pages/sales.jsx" /* webpackChunkName: "component---src-pages-sales-jsx" */),
  "component---src-pages-shop-orders-jsx": () => import("./../../../src/pages/shop-orders.jsx" /* webpackChunkName: "component---src-pages-shop-orders-jsx" */),
  "component---src-pages-suppliers-jsx": () => import("./../../../src/pages/suppliers.jsx" /* webpackChunkName: "component---src-pages-suppliers-jsx" */),
  "component---src-pages-transfers-jsx": () => import("./../../../src/pages/transfers.jsx" /* webpackChunkName: "component---src-pages-transfers-jsx" */),
  "component---src-pages-users-jsx": () => import("./../../../src/pages/users.jsx" /* webpackChunkName: "component---src-pages-users-jsx" */)
}

